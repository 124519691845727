
import { Component, Ref, Vue, Prop } from "vue-property-decorator";
import VModal from "@monade/vue-components/src/components/VModal.vue";
@Component({
  components: {
    VModal,
  },
})
export default class ConfirmModal extends Vue {
  @Ref() readonly modal!: VModal;
  @Ref() readonly createForm!: any;

  @Prop({ default: "Conferma" }) confirmBtnText!: string;
  @Prop({ default: "btn btn-primary" }) confirmBtnClass!: string;

  message = "";
  title = "";
  resourceId: any = null;

  show() {
    this.modal.show();
  }

  confirm(title: string, message: string, resourceId: string | number) {
    this.title = title;
    this.message = message;
    this.resourceId = resourceId;
    this.show();
  }

  hide() {
    this.modal.hide();
  }

  confirmed() {
    this.$emit("confirmed", this.resourceId);
    this.hide();
  }
}
